import React from "react";
import {Accordion, AccordionTab} from "primereact/accordion";
import Aux from "../../../../../../hoc/Auxiliary"
import SheetsConfigurations from "./SheetsConfigurations/SheetsConfigurations";


const QueriesDetailsConfigurations = (props) => {

    return (
        <Aux>
            <Accordion multiple activeIndex={[0]}>
                {
                    props.categories.map((cg) => {
                        return (
                            <AccordionTab key={cg.id} header={cg.name}>
                                <SheetsConfigurations sheetsDetails={props.sheetsDetails} setSheetsDetails={props.setSheetsDetails} category={cg} dataCollection={props.dataCollection}/>
                            </AccordionTab>)
                    })}
            </Accordion>
        </Aux>
    )
}

export default QueriesDetailsConfigurations;




