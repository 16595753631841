import React, {useCallback, useEffect, useState} from 'react';
import classes from "./Category.module.css";
import {Accordion, AccordionTab} from 'primereact/accordion';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {MultiSelect} from "primereact/multiselect";
import {Chip} from "primereact/chip";
import {ScrollPanel} from 'primereact/scrollpanel';
import 'primeicons/primeicons.css';
import {sortAlphabetically} from "../../../../../api/utils/sortArrays";
import {checkCategorySheetValidity, checkCategoryValidity} from "../../../../../api/utils/data-collection-form";
import {updateProperty} from "../../../../../api/utils/conditions-utils";
import {replaceMultiSpaceWithSingleSpace} from "../../../../../api/utils/Strings";
import MultiSelectWithAdd from "../../../DataCollections/Multiselect/MultiSelectWithAdd";

const Category = (props) => {
    const [cv, cm] = checkCategoryValidity(props.name, props.categories)
    const [sv, sm] = checkCategorySheetValidity(props.modelSheet, props.selectedSheets)
    const [isCategoryNameValid, setIsCategoryNameValid] = useState(updateProperty(props.collapsed, cv, true));
    const [categoryNameErrorMessage, setCategoryNameErrorMessage] = useState(updateProperty(props.collapsed, cm, ""));
    const [isCategorySheetsValid, setIsCategorySheetsValid] = useState(updateProperty(props.collapsed, sv, true));
    const [categorySheetsErrorMessage, setCategorySheetsErrorMessage] = useState(updateProperty(props.collapsed, sm, ""));
    const [firstClick, setFirstClick] = useState(true);

    useEffect(() => {
        const [v, m] = checkCategoryValidity(props.name, props.categories)
        setCategoryNameErrorMessage(m)
        setIsCategoryNameValid(v)
    }, [props.name, props.collapsed])

    useEffect(() => {
        const [v, m] = checkCategorySheetValidity(props.modelSheet, props.selectedSheets)
        setIsCategorySheetsValid(v)
        setCategorySheetsErrorMessage(m)
    }, [props.selectedSheets, props.collapsed])

    const handleChange = (event) => {
        updateFirstClick();
        props.onChange(event.target.name, event.target.value, props.elementIndex);
    }

    const deleteCategory = (index) => {
        props.onDelete(index);
    }

    const handleDelete = (p) => {
        const sheets = props.selectedSheets.filter(i => i.name !== p.name);
        props.onChange("sheets", sheets, props.elementIndex)
    };

    const updateFirstClick = useCallback(() => {
        if (firstClick) {
            setFirstClick(false);
        }
    }, [firstClick])

    const isCategoryNameErrorVisible = () => {
        return !updateProperty(props.collapsed,
            isCategoryNameValid,
            isCategoryNameValid || (firstClick && !props.isEditMode))
    }
    /**
     * Check if sheet error should be visible
     * if zone is collapsed --> return validation status
     * else return validation status or first click on component and not edit mode
     * */
    const isCategorySheetErrorVisible = () => {
        return !updateProperty(props.collapsed,
            isCategorySheetsValid,
            isCategorySheetsValid || (firstClick && !props.isEditMode))
    }

    return (<div>
        <br/>
        <div className="grid">
            <div className="col-1"/>
            <div className="col-8">
                <Accordion activeIndex={0} onBlur={updateFirstClick}>
                    <AccordionTab header={props.name}>
                        <div className="grid">
                            <div className="col-6">
                                <p><b>Name</b></p>
                                <InputText name="name" value={props.name}
                                           className={`${classes.inputStyle} ${updateProperty(isCategoryNameErrorVisible(), "p-invalid block", "")}`}
                                           onChange={(e) => handleChange({
                                               target: {
                                                   name: e.target.name,
                                                   value: replaceMultiSpaceWithSingleSpace(e.target.value)
                                               }
                                           })}
                                           onBlur={updateFirstClick}
                                />
                                {updateProperty(isCategoryNameErrorVisible(), <small
                                    className="p-error block">{categoryNameErrorMessage}</small>, null
                                )}
                                <p><b>Sheets </b></p>
                                <MultiSelect name="sheets" fixedPlaceholder={true} value={props.selectedSheets}
                                             disabled={props.dataModelDisabled}
                                             className={classes.dropDown}
                                             options={sortAlphabetically(props.modelSheet, 'name')}
                                             onChange={(e) => handleChange(e)}
                                             optionLabel="name"
                                             placeholder="Select Sheets" filter/>
                                {updateProperty(isCategorySheetErrorVisible(), <small
                                    className="p-error block">{categorySheetsErrorMessage}</small>, null
                                )}
                                <p><b>Tags </b></p>
                                <MultiSelectWithAdd name="tags" fixedPlaceholder={true} selectedTags={props.selectedTags}
                                             className={classes.dropDown}
                                             options={props.tags}
                                             onChange={(e) => handleChange(e)}
                                             optionLabel="name"
                                             placeholder="Select tags"/>
                            </div>
                            <div className="col-6">
                                <br/><br/>
                                <ScrollPanel className={classes.mainDiv}
                                             style={{borderColor: updateProperty(isCategorySheetErrorVisible(), 'red', 'lightgrey')}}>
                                    {props.selectedSheets.map((sh) => {
                                            let index = 1 + (Math.random() * 1000);
                                            return <Chip key={index} label={(
                                                sh.targetYearStart!==0?
                                                    <div>{sh.name}<b>({sh.targetYearStart}-{sh.targetYearEnd})</b></div>:
                                                    <div>{sh.name}</div>
                                            )}
                                                         onRemove={_ => handleDelete(sh)} className={"mb-2"}
                                                         removable/>
                                        }
                                    )}
                                </ScrollPanel>
                            </div>
                            <div className="col-11">
                                <br/>
                                <span className="pi pi-info-circle"/>
                                <small className={classes.iconInfo}>Sheets names are extracted from the chosen
                                    model</small>
                            </div>
                            <div className="col-1">
                                <Button type="button" className={classes.customButton} icon=" pi pi-trash"
                                        onClick={deleteCategory}/>
                            </div>

                        </div>
                    </AccordionTab>

                </Accordion>
            </div>
        </div>

    </div>)

}

export default Category;
