import React, { useState } from 'react';
import { Tag } from 'primereact/tag';

const TagList = (props) => {
    const [hovered, setHovered] = useState(false); // State to track hover

    // Function to handle hover on the "three dots"
    const handleHover = () => setHovered(true);
    const handleLeave = () => setHovered(false);

    // Get the first two tags and the rest
    const firstTwoTags = props.tags.slice(0, 2);
    const remainingTags = props.tags.slice(2);

    return (
        <div style={{ position: 'relative' }}>
            {/* Render the first two tags */}
            {firstTwoTags.map((tag) => (
                <Tag
                    key={tag.id}
                    style={{ backgroundColor: '#487c84', color: 'white', marginRight: '4px' }}
                    value={tag.name}
                    rounded
                />
            ))}

            {/* Render the "three dots" if there are more than 2 tags */}
            {props.tags.length > 2 && (
                <>
                    <Tag
                        style={{
                            backgroundColor: '#487c84',
                            color: 'white',
                            marginRight: '4px',
                            cursor: 'pointer',
                        }}
                        value="..."
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}
                        rounded
                    />

                    {/* Display the list of all tags on hover */}
                    {hovered && (
                        <div
                            style={{
                                position: 'absolute',
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                padding: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                borderRadius: '4px',
                                marginTop: '5px',
                                zIndex: 100,
                            }}
                        >
                            {props.tags.map((tag) => (
                                <div key={tag.id}>{tag.name}</div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TagList;
