import React from "react";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {Checkbox} from "primereact/checkbox";
import {downloadDetailedQuery} from "../../../../../../../api/file/download-service";

const SheetConfiguration = (props) => {

    const header = <div style={{
        fontSize: "1rem",
        fontWeight: "600",
        display: "flex",
        justifyContent: "center",
        paddingTop: "15px",
        color: "#487c84"
    }}>{props.sheet.name}</div>;

    function downloadQuery(s) {

        downloadDetailedQuery({
            dataCollectionId: props.dataCollection.id,
            sheetId: s.sheetId,
            categoryId: s.categorieId,
            queryName: s.queryName
        },s.QueryFileName).then(r => {
        })
    }

    return (
        <div>
            <Card header={header} style={{height:"210px", maxHeight: '210px', overflowY: 'auto', overflowX: 'hidden',  display: 'block' }}>

                <div >
                    {props.sheetDetails?.length !== 0 ? props.sheetDetails.map((s, i) => {
                        return (<>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div className=" flex align-items-center ">
                                        <Checkbox checked={s.isChecked}
                                                  onChange={() => {

                                                      const updatedSheetDetails = props.sheetsDetails.map(sheet =>
                                                          sheet.queryName === s.queryName
                                                              ? { ...sheet, isChecked: !sheet.isChecked }
                                                              : sheet
                                                      );
                                                      props.setSheetsDetails(updatedSheetDetails);

                                                  }}
                                        />
                                    </div>
                                    <div className="col-10">  {s.queryName} </div>

                                    <div className="col-2"><Button icon="pi pi-download"
                                                                   onClick={() => downloadQuery(s)}
                                                                   tooltip="Download query"
                                                                   className=" p-button-rounded-sm p-button-text"
                                                                   style={{
                                                                       height: '0.5rem',
                                                                       width: '1rem',
                                                                       color: '#487c84'
                                                                   }}
                                    /></div>
                                </div>

                            </>
                        )
                    }) : <span style={{ display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',fontSize: '14px',color:"#6f7575"}}>No queries uploaded</span>}

                </div>
            </Card>
        </div>
    )
}

export default SheetConfiguration;