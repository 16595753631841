import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showInfo} from "../notification/ToastManager";

export async function updateDefaultTags() {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let result = {};
    try {
        await axios.get(global.config.BACK_URL + "tag/updateDefaultTags/", header).then(response => {
            return result
        })
    } catch (e) {
        showError("Error", "An error was occurred when updating list of tags from keycloak.");
        result = {
            status: e.response.data.status,
            content: null
        }
        return result;
    }

    return result;

}

export async function getTags() {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let result = {};
    try {
        await axios.get(global.config.BACK_URL + "tag/getTags/", header).then(response => {
            if (response.data.status === 200) {
                result.content = response.data.content;
                result.status = response.data.status;
                if (result.content.length === 0) {
                    showInfo("Info", "there is no tags saved in database");
                }
            } else {
                showError("Error", "An error was occurred when retrieving list of tags");
                result = {
                    status: response.data.status,
                    content: null
                }
            }
            return result;
        })
    } catch (e) {
        showError("Error", "An error was occurred when retrieving list of tags");
        result = {
            status: e.response.data.status,
            content: null
        }
        return result;
    }

    return result;

}
