import axios from "axios";
import keycloak from "../../keycloak";
import {saveAs} from 'file-saver';
import {showError, showInfo, showSuccess} from "../notification/ToastManager";
axios.defaults.withCredentials = true
/**
 * Download Excel template and return the waiting status
 * - true : file is still waiting for generation (response.data.byteLength === 0 and response.status === 200)
 * - false :  file is generated or an error occur
 *
 * */
export async function generateTemplateExcel(body, fileName) {
    let response = await download("category/template/generate", fileName, body);
    if (response.status === 200) {
        if (response.data.byteLength > 0) {
            saveAs(new Blob([response.data],
                {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
            showSuccess("Success", `${fileName} is generated.`);
            return false;
        } else {
            showError("Error", `An error occurs when generating ${body["category"]["name"]} template.`);
            return true;
        }
    }
    return false;
}

export async function simulateTemplateExcel(body, fileName) {
    await downloadExcelFile("category/template/simulate", fileName, body)
}

/**
 * Download Excel file by its path in the file system
 * @param fileName the name which file will be downloaded with
 * @param filePath file Path in File System
 *
 * */
export async function downloadExcel(fileName, filePath) {
    await downloadExcelFile("file/download", fileName, {
        "filename": fileName,
        "filePath": filePath
    })
}

export async function downloadValidationReportZip(body, fileName) {
    await downloadZipFile("file/download/zip", fileName, body)
}

export async function downloadExcelFile(url, fileName, body) {
    await downloadFile(url, fileName, body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
}

export async function exportMarketNodeDataAsZip(body, dataType) {
    let fileName = body.dataCollection.name.replaceAll(" ", "_");
    if (body.marketNodes.length === 1) {
        fileName += "_" + body.marketNodes[0].name.replaceAll(" ", "_");
    }
    fileName += "_" + dataType + ".zip"
    await downloadZipFile("dataCollection/marketnode/export/" + dataType, fileName, body)
}

export async function exportQueryResultAsZip(fileName, fileUrl) {
    await downloadZipFile("file/download/zip", fileName, {
        "filename": fileName,
        "filePath": fileUrl
    })
}
export async function exportQueryResultAsCSV(fileName, fileUrl) {
    await downloadCSVFile("file/download/csv", fileName, {
        "filename": fileName,
        "filePath": fileUrl
    })
}

export async function downloadZipFile(url, fileName, body) {
    await downloadFile(url, fileName, body, "application/zip")
}
export async function downloadCSVFile(url, fileName, body) {
    await downloadFile(url, fileName, body, "text/csv")
}

export async function downloadFile(url, fileName, body, fileType) {
    try {
        await download(url, fileName, body).then(response => {
            if (response.status === 200) {
                saveAs(new Blob([response.data],
                    {type: fileType}), fileName);
                showSuccess("Success", `${fileName} is generated.`);
            } else if (response.status === 204) {
                showInfo("Info", "No data was available for export.");
            } else {
                showError("Error", "An error occurs while downloading " + fileName)
            }
        })
    } catch (e) {
        showError("Error", "An error occurs while downloading " + fileName)
    }
}

export async function download(url, fileName, body) {
    try {
        return await axios.post(global.config.BACK_URL + url, JSON.stringify(body),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token
                }, responseType: 'arraybuffer'
            });
    } catch (e) {
        showError("Error", "An error occurs while downloading " + fileName)
        return {status: 0, data: {byteLength: 0}};
    }
}
export async function downloadDetailedQuery(data,fileName) {
    try {
         await axios.post(global.config.BACK_URL +"file/download/query", data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + keycloak.token
                }, responseType: 'arraybuffer'
            }).then(r=>{

             if (r.data.byteLength > 0) {
                 saveAs(new Blob([r.data],
                     {type: 'text/turtle '}), fileName);}
         })


    } catch (e) {
        showError("Error", "An error occurs while downloading file " )
        return {status: 0, data: {byteLength: 0}};
    }
}