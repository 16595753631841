import { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {sortAlphabetically} from "../../../../api/utils/sortArrays";

export default function MultiSelectWithAdd(props) {
    const [newItem, setNewItem] = useState("");

    const addItem = () => {
    };
    const handleChange = (event) => {
        console.log(event);
        props.onChange(event);
    }

    return (
        <div className="p-1 gap-2 w-full">
            <div className="flex gap-3 pb-3 w-full">
                <InputText
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    placeholder="Add new tag"
                    className="flex-1"
                />
                <Button
                    onClick={addItem}
                    disabled={!newItem.trim()}
                    style={{backgroundColor: "#487c84", width: "110px"}}
                    label="Add tag"
                />
            </div>
            <MultiSelect
                name={props.name}
                value={props.selectedTags}
                options={sortAlphabetically(props.options, 'name')}
                optionLabel={props.optionLabel}
                onChange={(e) => handleChange(e)}
                placeholder={props.placeholder}
                display="chip"
                className="w-full"
                filter
            />
        </div>
    );
}
