import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showSuccess} from "../notification/ToastManager";

axios.defaults.withCredentials = true

export async function saveQuery(params) {
    try {
        await axios
            .post(global.config.BACK_URL + "file/upload/query", params, {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                },
            }).then(res => {
                if (res.data.status === 200) {
                    showSuccess("query has been uploaded successfully");
                } else {
                    showError("Error when uploading query");
                }
            }).catch((e) => {
                if (e.response.data.status === 500) {
                    showError(e.response.data.message);
                } else {
                    showError("An error occurs when uploading query");
                }

            })
    } catch (e) {
        showError("Error when uploading query");
    }

}

export async function saveOverviewQuery(params) {
    let result = {};
    try {

        await axios
            .post(global.config.BACK_URL + "file/upload/overview/query", params, {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                },
            }).then(res => {
                if (res.data.status === 200) {
                    showSuccess("The overview query " + params['queryName'] + " has been saved successfully");
                    result.status = 200;
                    result.content = res.data.content;
                }
            }).catch((err) => {
                showError("Error when uploading query");
                result.status = 400;
                result.content = null;
            })
    } catch (e) {
        showError("Error when uploading query");
        result.status = 400;
        result.content = null;
    }

    return result;
}

export async function getAllQueryFileNamesPerDataCollection(id) {

    let response = null;
    try {
        await axios
            .get(global.config.BACK_URL + "file/list/queryFileNames/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                },
            }).then(res => {
                if (res.data.status === 200) {
                    response = res.data.content;
                }
            }).catch(() => {
                showError("Error when retrieving list of queries  ");
            })
        return response;
    } catch (e) {
        showError("Error when retrieving list of queries  ");
        return response;
    }

}

export async function deleteSheetsQueries(params) {

    let response = {};
    try {
        await axios
            .post(global.config.BACK_URL + "file/delete/queries", params, {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                },
            }).then(res => {
                if (res.data.status === 200) {
                    showSuccess("Success", "Selected queries are deleted")
                    response.status = res.data.status;
                    response.content = res.data.content;
                } else {
                    response.status = res.data.status;
                    response.content = null;
                }
            }).catch(() => {
                showError("Error when deleting queries  ");
                response.status = 500;
                response.content = null;
            })
        return response;
    } catch (e) {
        showError("Error when deleting queries");
        return response;
    }

}

export async function getModelInfo(version) {
    let response = null;
    try {
        await axios
            .get(global.config.BACK_URL + "model/" + version, {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            }).then(res => {
                if (res.data.status === 200) {
                    response = res.data.content;
                }
            })
        return response;
    } catch (e) {
        return response;

    }
}

export async function uploadFile(formdata) {
    let response = null;

    try {
        response =
            await axios
                .post(global.config.BACK_URL + "file/upload", formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + keycloak.token
                    }
                })
    } catch (e) {
        showError("Error", `A problem was occurred when uploading file.`);
    }
    return response;
}
