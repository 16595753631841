import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showInfo} from "../notification/ToastManager";
axios.defaults.withCredentials = true
export async function getParticipants() {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let result = {};
    try {
        await axios.get(global.config.BACK_URL + "dataCollection/groups/", header).then(response => {
            if (response.data.status === 200) {
                result.content = response.data.content;
                result.status = response.data.status;
                if (result.content.length === 0) {
                    showInfo("Info", "there is no participant saved in database");
                }
            } else {
                showError("Error", "An error was occurred when retrieving list participants");
                result = {
                    status: response.data.status,
                    content: null
                }
            }
            return result

        })
    } catch (e) {
        showError("Error", "An error was occurred when retrieving list participants");
        result = {
            status: e.response.data.status,
            content: null
        }
        return result;
    }

    return result;

}
